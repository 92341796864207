import React, { useContext, useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Context as UserContext } from './UserContext'
import AlertPopup from './AlertPopup'
import ChangePassword from './ChangePassword'
import navigate from '../utils/navigate'

export default function PrivateRoute({ children }) {
  const data = useStaticQuery(graphql`
    query PrivateRouteComponent {
      site {
        siteMetadata {
          home
        }
      }
    }
  `)
  const HomeUrl = data.site.siteMetadata.home

  const { token } = useContext(UserContext)
  const [timer, setTimer] = useState(null)
  const [display, setDisplay] = useState(false)

  useLayoutEffect(() => {
    if (!token) {
      if (!timer) {
        setTimer(setTimeout(() => {
          if (process.env.NODE_ENV !== 'development') {
            navigate(HomeUrl)
          }
        }, 1000))
      }
    } else if (token && timer) {
      clearTimeout(timer)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [token, timer, HomeUrl])

  useLayoutEffect(() => {
    setDisplay(true)
  }, [])

  if (!display) {
    return null
  }

  return (
    <>
      <AlertPopup />
      <ChangePassword />
      {children}
    </>
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}
