import React, { useEffect, useState, useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby'
import axios from 'axios';
import random from '../utils/random';
import { Context as UserContext } from './UserContext';
import { Context as LeaveContext } from './LeaveContext';
import { ActiveLinkStyle } from './layout'

export default function StartLeaveRequestLink(props) {
  const data = useStaticQuery(graphql`
    query StartLeaveRequestLinkComoponent {
      site {
        siteMetadata {
          addLeaveRequestURL
        }
      }
    }
  `)
  const { addLeaveRequestURL } = data.site.siteMetadata
  const [Message, setMessage] = useState('Loading...')
  const { fetchData, Member: leaveRequestAvailable } = useContext(LeaveContext);
  const { forceChangePassword } = useContext(UserContext);

  useEffect(() => {
    if (!leaveRequestAvailable) {
      // API call to datasource for user data.
      // Example of using a variable inside of the HTML file to use inside of a react application
      // if(window.LeaveRequestURL)
      //   LeaveRequestURL = window.LeaveRequestURL
      axios
        .get(`${addLeaveRequestURL}?V=${random()}`)
        .then((res) => {
          // store the user data into the leaveRequest Store
          fetchData(res.data)
        })
        .catch((error) => {
          if (error.response.data.Message) {
            try {
              const Message = JSON.parse(error.response.data.Message)
              if (Message.IsForcePasswordChange) {
                forceChangePassword(true)
              }
            } catch (e) {
              setMessage('Error')
            }
          } else {
            setMessage('Error')
          }
        });
    }
  }, [fetchData, forceChangePassword, leaveRequestAvailable, addLeaveRequestURL])

  const { style, children, activateLink } = props
  const newStyle = {
    ...style,
    ...(activateLink ? ActiveLinkStyle : {}),
    ...(leaveRequestAvailable ? {} : { pointerEvents: 'none' }),
  }
  return (
    <Link
      activeStyle={ActiveLinkStyle}
      to="/New_Leave_Request_step1/"
      style={newStyle}
    >
      {leaveRequestAvailable ? children : Message || 'Error' }
    </Link>
  )
}
