import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

export default function HomeButton({ to }) {
  return (
    <div id="nav">
      <Link to={to} style={{ width: 53 }}>
        <div id="homebtn" />
      </Link>
    </div>
  )
}

HomeButton.defaultProps = {
  to: '/',
}

HomeButton.propTypes = {
  to: PropTypes.string,
}
