import React from 'react'

export default function Modal(props) {
  const CloseButtonStyle = {
    position: 'absolute',
    right: -16,
    top: -18,
    color: 'white',
    height: 22,
    width: 30,
    fontSize: 25,
    paddingTop: 4,
    borderRadius: '50%',
    border: '2px solid white',
    cursor: 'pointer',
    backgroundColor: 'black',
  }
  const BackgroundStyle = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    fontSize: 16,
    zIndex: 5000,
    backgroundColor: 'rgba(43,43,43,0.75)',
  }
  const ModalStyles = {
    backgroundColor: 'rgb(0,0,0)',
    fontSize: '2em',
    color: 'black',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 1000,
    textAlign: 'center',
  }

  const {
    status,
    closeControl,
    children,
    ...rest
  } = props
  return (
    <div style={{ ...BackgroundStyle, display: status ? 'block' : 'none' }} status={status || undefined}>
      <div style={ModalStyles} {...rest}>
        {closeControl
          ? <div tabIndex={0} role="button" style={CloseButtonStyle} onClick={closeControl} onKeyPress={closeControl}>X</div>
          : null}
        {children}
      </div>
    </div>
  )
}

Modal.defaultProps = {
  status: false,
  closeControl: false,
}
